<template>
  <section id="hero">
    <v-img
      :min-height="minHeight"
      max-height="600"
      :src="require('@/assets/atendimento.jpg')"
      class="white--text"
      gradient="to right, rgba(5, 11, 31, .8), rgba(5, 11, 31, .8)"
    >
      <v-container class="fill-height px-4 py-12">
        <v-responsive
          class="d-flex align-center"
          height="100%"
          max-width="700"
          width="100%"
        >
          <base-heading title="A forma definitiva para melhorar seu corpo e saúde!" />

          <base-body>
            <p>Conheça meu método de Nutrição Moderna e entenda como ele pode trazer resultados enquanto te permite ter uma alimentação simples e agradável. Não importa quem você seja, se está lendo isso então eu posso te ajudar!</p>
          </base-body>
        </v-responsive>
      </v-container>
    </v-img>
    <v-btn
      v-show="fab"
      v-scroll="onScroll"
      fab
      dark
      fixed
      bottom
      right
      color="blue-grey darken-2"
      @click="toTop"
    >
      <v-icon>mdi-arrow-up</v-icon>
    </v-btn>
  </section>
</template>

<script>
  export default {
    name: 'SectionHero',

    data () {
      return {
        fab: false,
      }
    },

    provide: {
      theme: { isDark: true },
    },

    computed: {
      minHeight () {
        const height = this.$vuetify.breakpoint.mdAndUp ? '100vh' : '50vh'

        return `calc(${height} - ${this.$vuetify.application.top}px)`
      },
    },

    methods: {
      onScroll (e) {
        if (typeof window === 'undefined') return
        const top = window.pageYOffset || e.target.scrollTop || 0
        this.fab = top > 20
      },
      toTop () {
        this.$vuetify.goTo(0)
      },
    },
  }
</script>
